<template>
  <div class="documents-list-page fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="documentsList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @row:clicked="handleRowClicked($event)"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getDocuments()"/>

    <custom-dialog ref="deleteDocumentConfirmation"
                   :title="$t('accountancy.documents.list.confirmation.deleteDocument.title')"
                   :body="$t('accountancy.documents.list.confirmation.deleteDocument.body')"
                   @accept="deleteDocument"/>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getDocuments} from '@/http/requests/documents'
import CustomDialog from '../../../../../components/customDialog/customDialog'
import {deleteDocument} from '../../../../../http/requests/documents'

export default {
  name: 'documentsList',
  metaInfo () {
    return {
      title: this.$t('accountancy.documents.list.title')
    }
  },
  components: {CustomDialog, DraggableDynamicTable},
  data () {
    return {
      requestSent: false,
      loadingTimer: 0,
      options: {
        id: 'documentsListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'type',
          i18n: 'accountancy.documents.list.table.header.type',
          width: 'calc(100% / 6)',
          minWidth: 100,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('accountancy.documents.list.table.filters.type.all'),
              value: 0
            },
            /*{
              label: this.$t('accountancy.documents.list.table.filters.type.cash'),
              value: 1
            },*/
            {
              label: this.$t('accountancy.documents.list.table.filters.type.receive'),
              value: 2
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.payment'),
              value: 3
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.withdrawal'),
              value: 4
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.transfer'),
              value: 5
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.income'),
              value: 6
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.cost'),
              value: 7
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.openingInventory'),
              value: 8
            },
            /*{
              label: this.$t('accountancy.documents.list.table.filters.type.salary'),
              value: 9
            },*/
            {
              label: this.$t('accountancy.documents.list.table.filters.type.purchase'),
              value: 10
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.returnPurchase'),
              value: 12
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.sale'),
              value: 11
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.returnSale'),
              value: 13
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.storeReceipt'),
              value: 14
            },
            {
              label: this.$t('accountancy.documents.list.table.filters.type.storeSend'),
              value: 15
            }
          ]
        },
        {
          field: 'description',
          i18n: 'accountancy.documents.list.table.header.description',
          width: 'calc(100% / 6 * 2)',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'creator',
          i18n: 'accountancy.documents.list.table.header.creator',
          width: 'calc(100% / 6 * 2)',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'created_at',
          i18n: 'accountancy.documents.list.table.header.createdAt',
          width: 'calc(100% / 6)',
          minWidth: 80,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'date',
          filterRange: true
        },
        {
          field: 'id',
          i18n: 'accountancy.documents.list.table.header.id',
          width: '100px',
          minWidth: 100,
          locked: true,
          align: 'center',
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      selectedDocument: {},
      data: [],
      filters: [],
      sorts: ['order[0]=created_at,desc'],
      page: 1,
      endedList: false,
      actions: [
        {
          /*toolbar: [
            {
              id: {name: 'insertDocument'},
              type: 'link',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success'
            }
          ],*/
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getDocuments()
  },
  methods: {
    getDocuments () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.documentsList && this.data.length === 0) this.$refs.documentsList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.documentsList && this.data.length > 0) this.$refs.documentsList.loadMoreStatus = 'Loading'

            getDocuments(null, this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0
              const documents = response.data.data
              // let banks = this.banks
              documents.forEach((document) => {
                this.data.push({
                  route: {name: 'document', params: {id: document.id}},
                  id: document.id,
                  creator: document.creator.name,
                  description: document.description,
                  type: document.type,
                  created_at: document.created_at
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('id')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.documentsList) this.$refs.documentsList.loadMoreStatus = ''

              this.requestSent = false
            }).catch(() => {

              /*if (this.$refs.documentsList)
                this.$refs.documentsList.loadMoreStatus = 'Danger'*/

              this.requestSent = false
            })
          } else {
            this.requestSent = false
          }
        }, 400)
      }
    },
    getDocumentReferenceRoute (type, reference) {
      const invoiceMapper = {
        '1': 'saleInvoice',
        '2': 'purchaseInvoice',
        '3': 'returnSaleInvoice',
        '4': 'returnPurchaseInvoice'
      }
      switch (type) {
      case 'Invoice':
        return {name: invoiceMapper[reference.type], params: {id: reference.id}}

      case 'StoreroomReceipt':
        return {name: 'storeReceipt', params: {id: reference.id}}

      case 'OpeningInventory':
        return {name: 'openingBalance'}

      default:
        return {}
      }
    },
    handleRowClicked (row) {
      if (!Object.keys(row.route).length) {
        this.$refs.deleteDocumentConfirmation.showDialog()
        this.selectedDocument = row
      }
    },
    deleteDocument () {
      deleteDocument(this.selectedDocument.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('accountancy.documents.notifications.delete.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })
      }).catch(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('accountancy.documents.notifications.delete.error'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    },
    setFilter (filters) {
      let filters_list = []
      const attributes = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'id':
          if (filters[key].search !== '') filters_list.push(`id=${filters[key].search},${filters[key].type.id}`)
          break

        case 'creator':
          if (filters[key].search !== '') filters_list.push(`creator=${filters[key].search}`)
          break

        case 'description':
          if (filters[key].search !== '') filters_list.push(`description=${filters[key].search}`)
          break

        case 'created_at':
          if (filters[key].search.length > 0) filters_list.push(`created_at=${filters[key].search.join('_')}`)
          break

        case 'type':
          if (filters[key].search.value > 0) filters_list.push(`type=${filters[key].search.value}`)
          break
        }
      })
      filters_list = [filters_list, ...attributes]

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getDocuments()
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

        case 'id':
          sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'creator':
          sorts_list.push(`order[0]=creator.name,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'user':
          sorts_list.push(`order[0]=user.name,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'type':
          sorts_list.push(`order[0]=type,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'status':
          sorts_list.push(`order[0]=status,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'date':
          sorts_list.push(`order[0]=invoice_date,${  sorts[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (sorts_list.length === 0) {
        this.sorts.push('order[0]=updated_at,desc')
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getDocuments()
    }
  }
}
</script>

<style scoped>

</style>
